/* eslint eqeqeq: "off" */
import React, { useState, useEffect, useCallback } from 'react';
import './index.css';
import moment from 'moment';
import { Table, Drawer, Menu } from 'antd'; //Row, Col, Button
import { Link } from 'react-router-dom';
import { cloneDeep, remove, findIndex } from 'lodash'; //find, isEmpty, sortedLastIndexOf
import {
  // searchDocuments,
  // searchDocFolders,
  getDocumentList,
  createDocumentFolder,
  updateDocFolder,
  searchDocLinks,
  createDocLinks,
  deleteLink,
} from '../../services/doc.service';
import DocumentsDetailPopup from './DocumentsDetailPopup';
import { docIcon, docThumbnailSrc } from '../../pages/Documents/DocUtils';
import {
  CompletionIcon,
  ThumbnailImage,
  ExpandingSearchPanel,
  CidekicButton,
  CidekicDropdown,
} from '../../components/common/common_ui';

// OK

const DocumentList = (props) => {
  const { windowSize, closeDrawer, docLinks, links, isMobile, documentsIn } = props;

  console.log(docLinks);

  let resto = JSON.parse(localStorage.getItem('restaurant')) || {};

  const [showSearch, setShowSearch] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [dataSource1, setDataSource1] = useState([]);
  const [origSource, setOriginal] = useState([]);
  const [pristineData, setPristineData] = useState([]);
  const [expandedRows, setExpandedRows] = useState([]);
  const [hasChanged, setHasChanged] = useState(false);
  const [documentid, setDocumentId] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  // const [visible, updateVisible] = useState(false);
  // const [visible1, updateVisible1] = useState(false);
  // const [visible2, updateVisible2] = useState(false);
  const [visible3, updateVisible3] = useState(false);
  // const [folderid, setFolderId] = useState('');
  const [rename, setRename] = useState(false);
  const [incTasks, setIncTasks] = useState('All');
  const [selectedDocument, setSelectedDocument] = useState({});
  const [allSelectedIds, setAllSelectedIds] = useState([]);
  const [allSelected, setAllSelected] = useState([]);

  useEffect(() => {
    searchDocLinks(docLinks).then((res) => {
      console.log(res);
      let links = res;
      let removeDeleted = links.filter((l) => l.Document.folderId !== null);
      let selectedLinks = removeDeleted.map((l, i) => {
        return {
          key: `document-${l.documentId}-${i}`,
          name: l?.Document?.info?.title && l?.Document?.info?.title !== '' ? l?.Document?.info?.title : 'Untitled',
          content: l?.Document?.title,
          type: l?.Document?.info?.type,
          date_uploaded: moment(l?.Document.createdAt).format('MM-DD-YYYY'), // BUG 1202144749571579
          documentId: l?.documentId,
          category: l?.Document?.info?.type,
          info: {
            file: l?.Document?.info?.file,
            comments: l?.Document?.info?.comments,
            date_updated: l?.Document?.info?.updatedDate,
          },
          id: l.documentId,
          upload_date: moment(l?.Document.createdAt).format('MM-DD-YYYY'), // BUG 1202144749571579
          attachments: l?.Document?.info?.comments?.length,
          assignee: l?.Document?.info?.createdBy,
        };
      });
      console.log(selectedLinks);
      setDataSource(selectedLinks);
      setOriginal(selectedLinks);

      const documentLinks = selectedLinks.map((d) => d.id).join(',');
      getDocumentList({ restaurantId: resto.id, documentLinks: documentLinks }).then((res) => {
        if (res && res.length > 0) {
          let storedDocs = JSON.parse(localStorage.getItem('documentOrder'));
          if (storedDocs && storedDocs.length > 0) {
            // removes the add button CTAs stored per folder row from Documents page table and updates relevant info"
            const storedDocsOnly = storedDocs.map((doc) => {
              const docsOnly = doc.children.filter((m) => m.type !== 'add-button');
              doc.children = docsOnly;
              doc.notes = docsOnly.filter((m) => m.type == 'Note').length;
              doc.uploads = docsOnly.filter((m) => m.type != 'Note').length;
              doc.selectedTasks = [].concat(docsOnly.map((d) => d.selectedTask).filter((u) => u !== undefined));
              return doc;
            })
            setDataSource1(storedDocsOnly);
            setPristineData(storedDocsOnly);
          } else {
            console.log('TO TABLE', res);
            setDataSource1(res);
            setPristineData(res);
          }
        }
      });
      // searchDocFolders({ restaurantId: resto.id }).then((res) => {
      //   let folders = [];
      //   if (res) {
      //     let folderInfo = res.documentfolders;
      //     for (let i = 0; i < (folderInfo || []).length; i++) {
      //       const { id, title } = folderInfo[i];

      //       let documentData = [];

      //       folders.push({
      //         key: id,
      //         folderId: id,
      //         name: title,
      //         type: 'parent',
      //         // children: documentData,
      //         children: [],
      //       });

      //       console.log(documentData);
      //       console.log(folders);
      //     }
      //     let getFolderIds = folders.map((f) => f.key);
      //     console.log(getFolderIds);

          // const fetchData = () => {
          //   const allRequests = getFolderIds.map((e) => searchDocuments({ folderId: e }).then((res) => res));

          //   return Promise.all(allRequests);
          // };

          // fetchData().then((res) => {
          //   let docList = res;
          //   let merged = [].concat.apply([], docList);

          //   console.log(merged);

          //   let docListData = merged.map((d, i) => {
          //     return {
          //       key: `folder-${d.folderId}-document-${d.id}-${i}`,
          //       folderId: d.folderId,
          //       id: d.id,
          //       name: d.info?.title && d.info?.title !== '' ? d.info?.title : 'Untitled',
          //       content: d.title && d.title !== '' ? d.title : '',
          //       type: 'child',
          //       info: d.info,
          //       category: d.info?.type,
          //       selectedTask: d.info?.selectedTasks,
          //       assignee: d.info?.createdBy,
          //       upload_date: moment(d.createdAt).format('MM-DD-YYYY'),
          //       linked: d.info?.linked,
          //       attachments: d.info?.comments?.length,
          //       details: 'true',
          //     };
          //   });

          //   console.log(docListData);

          //   let dataCombined = folders.concat(docListData);

          //   console.log(dataCombined);

          //   const result = Object.values(
          //     dataCombined.reduce((r, o, i) => {
          //       const key = `${o.folderId}`;

          //       if (!r[key])
          //         r[key] = {
          //           ...o,
          //         };

          //       r[key].children.unshift(o);

          //       r[key].children = [].concat
          //         .apply([], r[key].children)
          //         .filter((u) => u !== undefined && u.type !== 'parent');

          //       r[key].notes = r[key]?.children?.filter((c) => c?.info?.type == 'Note').length;

          //       r[key].uploads = r[key]?.children?.filter(
          //         (c) => c?.info?.type !== 'Note' && c.type !== 'add-button'
          //       ).length;

          //       r[key].selectedTasks = [].concat.apply(
          //         [],
          //         r[key]?.children?.map((t) => t.selectedTask).filter((u) => u !== undefined)
          //       );

          //       r[key].children.sort((x, y) => {
          //         return x.id - y.id;
          //       });

          //       console.log(r[key]);

          //       return r;
          //     }, {})
          //   );

          //   console.log('RESULT', result);

          //   let ids = selectedLinks.map((d) => d.documentId);

          //   console.log(ids);

          //   const idMap = result.reduce(function merge(map, node) {
          //     map[node.id] = node;

          //     if (Array.isArray(node.children)) {
          //       node.children.reduce(merge, map);
          //     }

          //     return map;
          //   }, {});

          //   console.log(idMap);

          //   const items = ids.map((id) => idMap[id]);
          //   items.forEach((item) => (item.checked = true));

          //   console.log(items);
          // });
          // });
        // }
    });
  }, [docLinks, hasChanged, resto.id]);

  const getAllFolders = () => {
    getDocumentList({ restaurantId: resto.id }).then((res) => {
      if (res && res.length > 0) {
        setDataSource1(res);
        setPristineData(res);
      }
    });
    // searchDocFolders({ restaurantId: resto.id }).then((res) => {
    //   let folders = [];
    //   if (res) {
    //     let folderInfo = res.documentfolders;
    //     for (let i = 0; i < (folderInfo || []).length; i++) {
    //       const { id, title } = folderInfo[i];

    //       let documentData = [];

    //       // documentData.push({
    //       //   // always on the last row
    //       //   key: Math.random().toString(),
    //       //   type: 'add-button',
    //       //   parent_id: id,
    //       // });

    //       folders.push({
    //         key: id,
    //         folderId: id,
    //         name: title,
    //         type: 'parent',
    //         // children: documentData,
    //         children: [],
    //       });

    //       console.log(documentData);
    //       console.log(folders);
    //     }
    //     getDocuments(folders);
    //   }
    // });
  };

  // const getDocuments = (folders) => {
  //   let getFolderIds = folders.map((f) => f.key);
  //   console.log(getFolderIds);

  //   const fetchData = () => {
  //     const allRequests = getFolderIds.map((e) => searchDocuments({ folderId: e }).then((res) => res));

  //     return Promise.all(allRequests);
  //   };

  //   fetchData().then((res) => {
  //     let docList = res;
  //     let merged = [].concat.apply([], docList);

  //     console.log(merged);

  //     let docListData = merged.map((d) => {
  //       return {
  //         folderId: d.folderId,
  //         id: d.id,
  //         name: d.info?.title && d.info?.title !== '' ? d.info?.title : 'Untitled',
  //         content: d.title && d.title !== '' ? d.title : '',
  //         type: 'child',
  //         info: d.info,
  //         category: d.info?.type,
  //         selectedTask: d.info?.selectedTasks,
  //         assignee: d.info?.createdBy,
  //         upload_date: moment(d.createdAt).format('MM-DD-YYYY'), // BUG 1202144749571579
  //         linked: d.info?.linked,
  //         attachments: d.info?.comments?.length,
  //         details: 'true',
  //       };
  //     });

  //     console.log(docListData);

  //     let dataCombined = folders.concat(docListData);

  //     console.log(dataCombined);

  //     const result = Object.values(
  //       dataCombined.reduce((r, o, i) => {
  //         const key = `${o.folderId}`;

  //         if (!r[key])
  //           r[key] = {
  //             ...o,
  //           };

  //         r[key].children.unshift(o);

  //         r[key].children = [].concat.apply([], r[key].children).filter((u) => u !== undefined && u.type !== 'parent');

  //         r[key].notes = r[key]?.children?.filter((c) => c?.info?.type == 'Note').length;

  //         r[key].uploads = r[key]?.children?.filter((c) => c?.info?.type !== 'Note' && c.type !== 'add-button').length;

  //         r[key].selectedTasks = [].concat.apply(
  //           [],
  //           r[key]?.children?.map((t) => t.selectedTask).filter((u) => u !== undefined)
  //         );

  //         r[key].children.sort((x, y) => {
  //           return x.id - y.id;
  //         });

  //         console.log(r[key]);

  //         return r;
  //       }, {})
  //     );

  //     console.log('RESULT', result);
  //     console.log(
  //       'LINKS',
  //       dataSource.map((d) => d.documentId)
  //     );

  //     let ids = dataSource.map((d) => d.documentId);

  //     const idMap = result.reduce(function merge(map, node) {
  //       map[node.id] = node;

  //       if (Array.isArray(node.children)) {
  //         node.children.reduce(merge, map);
  //       }

  //       return map;
  //     }, {});

  //     const items = ids.map((id) => idMap[id]);
  //     items.forEach((item) => (item.checked = true));

  //     console.log(items);

  //     setDataSource1(result);
  //     setPristineData(result);
  //   });
  // };

  // useEffect(() => {
  // searchDocuments({ folderId: '87' }).then((res) => {
  //   let docs = res;
  //   console.log(docs);
  // });
  //   getAllFolders();
  // }, [hasChanged, dataSource]);

  const onClose = () => {
    closeDrawer(true);
  };

  const onClose1 = () => {
    updateVisible3(false);
  };

  const closedDrawer = useCallback((data) => {
    updateVisible3(false);
  }, []);

  const detachDocument = (obj) => {
    deleteLink(obj.id, documentsIn.toString(), links.linkId).then((res) => {
      closeDrawer(true);
    });
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      ellipsis: true,
      width: () => {
        if (documentsIn === 'tasks' || documentsIn === 'menus') {
          return isMobile ? 200 : 400;
        }

        if (documentsIn === 'kitchen' || documentsIn === 'events') {
          return '55%';
        }
      },
      render: (key, obj) => (
        <span onClick={(e) => showDrawer(e, obj)} style={{ cursor: 'pointer' }}>
          {obj.type !== 'Note' ? obj.content : key}
        </span>
      ),
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: 'Date uploaded',
      dataIndex: 'upload_date',
      render: (key, obj) => <span>{key}</span>,
      width: () => {
        if (documentsIn === 'kitchen' || documentsIn === 'events') {
          return '55%';
        } else {
          return;
        }
      },
      ellipsis: () => {
        if (documentsIn === 'kitchen' || documentsIn === 'events') {
          return true;
        } else {
          return false;
        }
      },
    },
    {
      title: '',
      dataIndex: 'detach',
      width: 120,
      render: (key, obj) => (
        <span className="detach-docs" onClick={() => detachDocument(obj)}>
          Detach
        </span>
      ),
    },
  ];

  const handleRowExpand = (record) => {
    if (expandedRows.includes(record.key)) {
      let filterRecord = expandedRows.filter((key) => key !== record.key);
      setExpandedRows(filterRecord);
    } else {
      setExpandedRows([...expandedRows, record.key]);
    }
  };

  const toggleSelect = (e, obj, status) => {
    if (status == 'selected') {
      let addSelectedLinks = dataSource1.map((o) => {
        o.children.map((c) => {
          if (obj.id == c.id) {
            c.checked = false;
          }
          return c;
        });
        return o;
      });

      setDataSource1(addSelectedLinks);
      setPristineData(addSelectedLinks);
      let filteredIds = allSelectedIds.filter((item) => item !== obj.id);
      setAllSelectedIds(filteredIds);

      //call delete link API
      // documentsIn: 'tasks', 'menus', 'kitchen', 'events', 'recipes'
      deleteLink(obj.id, documentsIn.toString(), links.linkId).then((res) => res);
    } else {
      let addSelectedLinks = dataSource1.map((o) => {
        o.children.map((c) => {
          if (obj.id == c.id) {
            c.checked = true;
          }
          return c;
        });
        return o;
      });

      const value = [
        {
          linkId: links.linkId,
          info: { title: { label: links.info?.title, value: links.linkId } },
          type: documentsIn.toString(),
        },
      ];
      setDataSource1(addSelectedLinks);
      setPristineData(addSelectedLinks);
      setAllSelectedIds((oldArray) => [...oldArray, obj.id]);
      setAllSelected(value);
    }
  };

  const columns1 = [
    {
      title: 'Name',
      dataIndex: 'name',
      sorter: (a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0),
      sortDirections: ['descend', 'ascend'],
      width: (isMobile ? '55%' : 300),
      ellipsis: true,
      render: (key, obj) => (
        <span
          className={
            documentsIn === 'kitchen' || documentsIn === 'events' ? 'doc-attached-checkmark-container-mobile' : ''
          }
        >
          <span className={obj.type == 'parent' ? '' : 'doc-name-key1'}>
            {obj.type == 'child' ? (
              <span>
                {obj.checked ? (
                  <span
                    className={
                      documentsIn === 'kitchen' || documentsIn === 'events'
                        ? 'doc-attached-checkmark'
                        : 'checkmark-icon'
                    }
                    onClick={(e) => toggleSelect(e, obj, 'selected')}
                  >
                    <CompletionIcon completed={true} dimmed={false} />
                  </span>
                ) : (
                  <span
                    className={
                      documentsIn === 'kitchen' || documentsIn === 'events'
                        ? 'doc-attached-checkmark'
                        : 'checkmark-icon'
                    }
                    onClick={(e) => toggleSelect(e, obj, 'unselected')}
                  >
                    <CompletionIcon completed={false} dimmed={documentsIn === 'kitchen' || documentsIn === 'events'} />
                  </span>
                )}
              </span>
            ) : (
              ''
            )}
            {obj.type == 'child' ? (
              <ThumbnailImage
                className="doc-attached-image-container"
                src={docThumbnailSrc(obj)}
                placeholderIcon={docIcon(obj)}
                onClick={(e) => showDrawer(e, obj)}
              />
            ) : (
              ''
            )}
            {obj.type == 'child' || obj.type == 'add-button' ? (
              <span className="doc-attached-title-container" onClick={(e) => setDocProps(e, obj)}>
                {key && (
                  <span>
                    <Link
                      target={obj.category !== 'Note' ? '_blank' : ''}
                      to={
                        obj.category !== 'Note'
                          ? {
                              pathname: `/document/public/${obj.id}`,
                            }
                          : '/documents'
                      }
                    >
                      {' '}
                      {obj.category !== 'Note' ? obj.content : key}
                    </Link>
                  </span>
                )}
              </span>
            ) : obj.type === 'parent' && documentid == obj.key && isEditing ? (
              <input
                autoFocus
                type="text"
                className="document-bar1"
                defaultValue={obj.name}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    handleAddDocumentFolder(obj);
                  }
                }}
                onBlur={(e) => saveDocumentFolder(e, obj)}
                onChange={(e) => {
                  const cloneList = cloneDeep(dataSource);
                  const index = findIndex(cloneList, { key: obj.key });
                  cloneList[index].name = e.target.value;
                  // setDataSource(cloneList);
                }}
                placeholder="Enter document folder name"
              />
            ) : (
              <span className="attached-doc-folder-title">{key}</span>
            )}

            {/*
            {obj.type === 'parent' && obj.key !== 0 ? (
              <div className="attached-doc-upload-count">
                ( {obj.children.filter((j) => j.checked !== obj.checked).length} of {obj.children.length} selected )
              </div>
            ) : (
              ''
            )}

            {obj.type == 'child' && obj.attachments > 0 ? (
              <span className={'doc-attached-attachments-count'}>{obj.attachments}</span>
            ) : (
              ''
            )}
            {obj.type == 'child' && obj.attachments > 0 ? (
              <span className={'doc-attached-attachments-icon'}>
                {' '}
                <img src={attach} className="comment" alt="comment" />
              </span>
            ) : (
              ''
            )}
            */}
            {/* {obj.type == 'add-button' ? (
              <span>
                {!key && (
                  <span className="doc-item-add" onClick={(e) => showDocForm(e, obj.parent_id)}>
                    Upload
                  </span>
                )}
                {!key && (
                  <span className="doc-item-add" onClick={(e) => showNote(e, obj.parent_id)}>
                    Create note
                  </span>
                )}
              </span>
            ) : (
              ''
            )} */}
          </span>
        </span>
      ),
    },
    {
      title: 'Type',
      dataIndex: 'category',
      sorter: (a, b) => (a.category < b.category ? -1 : a.category > b.category ? 1 : 0),
      sortDirections: ['descend', 'ascend'],
      width: (isMobile ? '20%' : '20%'),
      ellipsis: true,
      render: (key, obj) => <span>{obj.type == 'child' && key ? <span>{key}</span> : ''}</span>,
    },
    {
      title: (isMobile ? 'Uploaded' : 'Date uploaded'),
      dataIndex: 'upload_date',
      width: (isMobile ? '25%' : '25%'),
      sorter: (a, b) => (a.upload_date < b.upload_date ? -1 : a.upload_date > b.upload_date ? 1 : 0),
      sortDirections: ['descend', 'ascend'],
    },
    // {
    //   title: 'Linked',
    //   dataIndex: 'linked',
    //   sorter: (a, b) => (a.linked < b.linked ? -1 : a.linked > b.linked ? 1 : 0),
    //   sortDirections: ['descend', 'ascend'],
    // },
    // {
    //   title: 'Initiated by',
    //   dataIndex: 'assignee',
    //   sorter: (a, b) => (a.assignee < b.assignee ? -1 : a.assignee > b.assignee ? 1 : 0),
    //   sortDirections: ['descend', 'ascend'],
    //   ellipsis: true,
    //   render: (key, obj) => (
    //     <span>
    //       {obj.type == 'child' && key ? <img src={user} className="user" alt="user-photo" /> : ''}
    //       {obj.type == 'child' && key ? <span>{key}</span> : ''}
    //     </span>
    //   ),
    // },
    // {
    //   title: 'Details',
    //   dataIndex: 'details',
    //   sorter: (a, b) => (a.details < b.details ? -1 : a.details > b.details ? 1 : 0),
    //   sortDirections: ['descend', 'ascend'],
    //   render: (key, obj) => (
    //     <span>
    //       {obj.type == 'child' && (
    //         <span className="docs-details" onClick={(e) => showDrawer(e, obj)}>
    //           Details
    //         </span>
    //       )}
    //     </span>
    //   ),
    // },
    // {
    //   title: '',
    //   dataIndex: 'delete',
    //   width: 50,
    //   render: (key, obj) => (
    //     <span>
    //       {/* {obj.type == 'child' && (
    //         <span>
    //           {' '}
    //           <Dropdown overlay={deletesTask(obj)}>
    //             <div className="toggle-switch" onMouseOver={(e) => setDeleteTask(obj)}>
    //               <img src={IconsMoreOff} className="UI-IconsMoreOff" alt="IconsMoreOff" />
    //             </div>
    //           </Dropdown>
    //         </span>
    //       )} */}

    //       {obj.type == 'parent' && obj.key !== 0 && (
    //         <span>
    //           {' '}
    //           <Dropdown overlay={docFolder(obj)}>
    //             <div className="toggle-switch">
    //               <img src={IconsMoreOff} className="UI-IconsMoreOff" alt="IconsMoreOff" />
    //             </div>
    //           </Dropdown>
    //         </span>
    //       )}
    //     </span>
    //   ),
    // },
  ];

  const setDocProps = (e, obj) => {
    console.log(obj);
    if (obj.category !== 'Note') {
      localStorage.setItem(
        'docProps',
        JSON.stringify({
          name: obj.name,
          date: obj.upload_date,
          file: obj.info?.file,
          type: obj.info?.type,
        })
      );
    } else {
      localStorage.removeItem('docProps');
    }
  };

  const handleAddDocumentFolder = (info) => {
    setHasChanged(true);
    if (rename) {
      updateDocFolder(info?.key, { title: info?.name.trim() }).then((res) => {
        setIsEditing(false);
        setHasChanged(false);
        setDocumentId('0');
        setRename(false);
        getAllFolders();
      });
    } else {
      createDocumentFolder({ title: info?.name.trim(), restaurantId: resto.id }).then((res) => {
        setHasChanged(false);
        setIsEditing(false);
      });
    }
  };

  const saveDocumentFolder = (e, info) => {
    e.preventDefault();
    console.log('DOC FOLDER', info);
    if (!info?.name) {
      const clonedList = cloneDeep(dataSource);
      remove(clonedList, (cl) => cl.key === 0);
      console.log(clonedList);
      // setDataSource(clonedList);
    } else {
      if (rename) {
        updateDocFolder(info?.key, { title: info?.name.trim() }).then((res) => {
          setIsEditing(false);
          setHasChanged(false);
          setDocumentId('0');
          setRename(false);
          getAllFolders();
        });
      } else {
        createDocumentFolder({ title: info?.name.trim(), restaurantId: resto.id }).then((res) => {
          setHasChanged(false);
          setIsEditing(false);
        });
      }
    }
  };

  const showDrawer = (e, obj) => {
    if (documentsIn !== 'menus') {
      //updateVisible(true);
    }
    updateVisible3(true);
    setSelectedDocument(obj);
    if (obj.category !== 'Note') {
      localStorage.setItem(
        'docProps',
        JSON.stringify({
          name: obj.name,
          date: obj.upload_date,
          file: obj.info?.file,
          type: obj.type,
        })
      );
    } else {
      localStorage.removeItem('docProps');
    }
  };

  // const showNote = (e, folderId) => {
  //   e.preventDefault();
  //   updateVisible1(true);
  //   setFolderId(folderId);
  // };

  // const showDocForm = (e, folderId) => {
  //   e.preventDefault();
  //   updateVisible2(true);
  //   setFolderId(folderId);
  // };

  // const docFolder = (action) => {
  //   return (
  //     <Menu className="menu-show-preview">
  //       <Menu.Item className="menu-show-preview" onClick={() => renameFolder(action)}>
  //         <a>Rename</a>
  //       </Menu.Item>
  //       <Menu.Item className="menu-show-preview" onClick={() => deleteFolder(action)}>
  //         <a>Delete</a>
  //       </Menu.Item>
  //     </Menu>
  //   );
  // };

  // const renameFolder = (info) => {
  //   console.log('RENAME THIS FOLDER', info?.key);
  //   setDocumentId(info?.key);
  //   setIsEditing(true);
  //   setRename(true);
  // };

  // const deleteFolder = (info) => {
  //   console.log(info);
  //   if (info?.key > 0) {
  //     setHasChanged(true);
  //     destroyDocFolder(info?.key).then((res) => {
  //       console.log('Resss', res);
  //       setHasChanged(false);
  //       notification.open({
  //         message: 'Document Folder Successfully deleted!',
  //       });
  //     });
  //   } else {
  //     const clonedList = cloneDeep(dataSource);
  //     remove(clonedList, (cl) => cl.key === 0);
  //     console.log(clonedList);
  //     setDataSource(clonedList);
  //   }
  // };

  const incompleteTasks = (
    <Menu className="menu-show-preview">
      <Menu.Item className="menu-show-preview" onClick={(e) => filterTask('all')}>
        <span className="doc-inc-tasks">All</span>
      </Menu.Item>
      <Menu.Item className="menu-show-preview" onClick={(e) => filterTask('notes')}>
        <span className="doc-inc-tasks">Notes</span>
      </Menu.Item>
      <Menu.Item className="menu-show-preview" onClick={(e) => filterTask('documents')}>
        <span className="doc-inc-tasks">Documents</span>
      </Menu.Item>
    </Menu>
  );

  const filterTask = (status) => {
    if (status == 'documents') {
      let documents = origSource.filter((obj) => obj.type !== 'Note');
      setDataSource(documents);
      setIncTasks('All');
    } else if (status == 'notes') {
      let notes = origSource.filter((obj) => obj.type == 'Note');
      setDataSource(notes);
      setIncTasks('Notes');
    } else if (status == 'all') {
      let all = origSource;
      setDataSource(all);
      setIncTasks('All');
    }
  };

  const saveClose = () => {
    console.log(allSelectedIds);
    console.log(allSelected);
    console.log(links);

    const fetchData = () => {
      const allRequests = allSelectedIds.map((e) => createDocLinks(e, allSelected).then((res) => res));

      return Promise.all(allRequests);
    };

    fetchData().then((res) => {
      // notification.open({
      //   message: 'Document Successfully updated!',
      // });
      closeDrawer(true);
    });
  };

  const searchAllArr = (text) => (value) => {
    if (!value) return false;
    const valueType = typeof value;

    if (valueType === 'string') {
      return value.toLowerCase().indexOf(text.toLowerCase()) > -1;
    }
    if (Array.isArray(value)) {
      return value.some(searchAllArr(text));
    }
    if (valueType === 'object') {
      return Object.values(value).some(searchAllArr(text));
    }
    return false;
  };

  const searchDoc = (e) => {
    let keyword = e.target.value;
    const filtered = origSource.filter((entry) =>
      Object.values(entry).some((val) => typeof val === 'string' && val.includes(keyword))
    );
    setDataSource(filtered);

    //BUG 1202401628137316
    const clonedData = cloneDeep(pristineData);

    let searchTags = clonedData.filter(searchAllArr(keyword));

    if (searchTags.length === 1) {
      if (searchTags[0].children.length === 1) {
        setDataSource1([searchTags[0].children[0]]);
      } else {
        setDataSource1(searchTags);
      }
    } else {
      setDataSource1(searchTags);
    }
  };

  return (
    <div>
      <br />
      <div
        className={
          documentsIn === 'tasks' || documentsIn === 'menus' ? 'add-course-box-update' : 'add-course-box-update'
        }
      >
        <CidekicButton className="button-highlight" alt="Save and close" onClick={saveClose}>
          Save and close
        </CidekicButton>
        {windowSize.width > 700 ? (
          <div className="close-drawer flex-control-right" onClick={onClose}>
            X <span className="close-text">Close</span>
          </div>
        ) : (
          <div className="close-drawer flex-control-right" onClick={onClose}>
            X
          </div>
        )}
      </div>
      <br />
      <div
        className={
          documentsIn === 'tasks' || documentsIn === 'menus' ? 'add-course-box-update' : 'add-course-box-update'
        }
      >
        <CidekicDropdown overlay={incompleteTasks} className="doc-drawer-select">
          {incTasks}
        </CidekicDropdown>
        <ExpandingSearchPanel
          className="flex-control-right"
          showSearch={showSearch}
          searchPrompt="Search documents"
          onChange={searchDoc}
          onClick={() => setShowSearch(true)}
        />
      </div>
      <br />
      <div className="doc-table1">
        <Table dataSource={dataSource} columns={columns} pagination={false} />{' '}
      </div>
      <br />
      <div className={documentsIn === 'tasks' || documentsIn === 'menus' ? 'add-course-box' : 'doc-panel-container'}>
        <div>
          <div>
            <div className="add-ing-text1">Add more documents</div>
          </div>
          <br />
          <div className="doc-attached-table">
            <Table
              columns={columns1}
              dataSource={dataSource1}
              rowClassName={(record, index) => (record.type == 'parent' ? 'parent' : 'child')}
              pagination={false}
              onExpand={(expanded, record) => handleRowExpand(record)}
              expandedRowKeys={expandedRows}
            />
          </div>
        </div>
      </div>
      <Drawer
        width={windowSize.width > 800 ? '400px' : '100%'}
        onClose={onClose1}
        visible={visible3}
        closable={false}
        drawerStyle={{ backgroundColor: 'var(--main-fill)' }}
      >
        <DocumentsDetailPopup data={selectedDocument} closedDrawer={closedDrawer} documentsFor={documentsIn} />
      </Drawer>
    </div>
  );
};

export default DocumentList;
