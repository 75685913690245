import React, { useState, useEffect, useCallback } from 'react';
import collapseall from '../../img/check-collapse.png';
import { Table, Drawer, Dropdown, Menu, Row, Col, Button, notification } from 'antd';
import comment from '../../img/comment.png';
import { pluralize } from 'inflected';
// import attach from '../../img/attach.png';
// import { data } from '../../data/documentdata';
import useWindowSize from '../../components/windowdimensions/windowSize';
import CreateNotePopup from './CreateNotePopup';
import UploadDocument from './UploadDocument';
import DocumentsDetailPopup from './DocumentsDetailPopup';
// import { Link } from 'react-router-dom';
import { CloseCircleOutlined } from '@ant-design/icons';
import {
  searchDocuments,
  searchDocFolders,
  createDocumentFolder,
  destroyDocFolder,
  updateDocFolder,
  getLinks,
  // deleteAllLinks,
} from '../../services/doc.service';
import { cloneDeep, find, remove, findIndex } from 'lodash';
import _ from 'lodash';
import moment from 'moment';
import IconsMoreOff from '../../img/ui-icons-more-off.svg';
import empty from '../../img/cidekic_logo_bw.svg';
import { Empty } from 'antd';
import { docIcon, docThumbnailSrc } from './DocUtils';
import { SubHeaderMobile } from '../../components/Header/HeadMobile.js';
import { ExpandCollapseIcon, PlusButton, UserImage, ThumbnailImage } from '../../components/common/common_ui';

const newDocsValue = {
  key: '0',
  name: '',
  type: 'parent',
  children: [],
};

const DocumentsMobile = () => {
  const [expandedRows, setExpandedRows] = useState([1, 2]);
  const [showSearch, setShowSearch] = useState(false);
  const [visible, updateVisible] = useState(false);
  const [visible1, updateVisible1] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [restaurant, setRestaurant] = useState('');
  const [origSource, setOriginal] = useState([]);
  const [hasChanged, setHasChanged] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [documentid, setDocumentId] = useState('');
  const [rename, setRename] = useState(false);
  const [folderid, setFolderId] = useState('');
  const [visible2, updateVisible2] = useState(false);
  const [typeName, setTypeName] = useState('');
  const [selectedDocument, setSelectedDocument] = useState({});
  const [userImage, setUserImage] = useState(
    JSON.parse(localStorage.getItem('operator'))?.info?.userImageThumb ||
      JSON.parse(localStorage.getItem('operator'))?.info?.userImage
  );
  const [isFiltering, setIsFiltering] = useState(false);
  const [isSearching, setIsSearching] = useState(false);

  let resto = JSON.parse(localStorage.getItem('restaurant')) || {};
  let userInfo = JSON.parse(localStorage.getItem('operator')) || {};
  let userName = userInfo.firstName + ' ' + userInfo.lastName;

  // useEffect(() => {
  //   data.forEach((item) =>
  //     item.children.forEach((subItem, index) => {
  //       if (typeof subItem.key === 'string') {
  //         return item.children.splice(index, 1);
  //       }
  //     })
  //   );

  //   console.log(data);
  //   let dataSrc = data.map((d) => {
  //     d.children.push({ key: `${d.folderId}-0`, name: '' });
  //     return d;
  //   });

  //   setDataSource(dataSrc);
  // }, []);

  useEffect(() => {
    let resto = JSON.parse(localStorage.getItem('restaurant')) || {};
    setRestaurant(resto.name);
  }, []);

  useEffect(() => {
    getAllFolders();
  }, [hasChanged]);

  const windowSize = useWindowSize();

  const getAllFolders = () => {
    searchDocFolders({ restaurantId: resto.id }).then((res) => {
      let folders = [];
      if (res) {
        let folderInfo = res.documentfolders;
        for (let i = 0; i < (folderInfo || []).length; i++) {
          const { id, title } = folderInfo[i];

          let documentData = [];

          documentData.push({
            // always on the last row
            key: `${id}-9999999`,
            type: 'add-button',
            parent_id: id,
          });

          folders.push({
            key: `${id}`,
            folderId: id,
            name: title,
            type: 'parent',
            children: documentData,
          });

          console.log(documentData);
          console.log(folders);
        }
        getDocuments(folders);
      }
    });
  };

  const getDocuments = (folders) => {
    let getFolderIds = folders.map((f) => f.key);
    console.log(getFolderIds);

    const fetchData = () => {
      const allRequests = getFolderIds.map((e) => searchDocuments({ folderId: e }).then((res) => res));

      return Promise.all(allRequests);
    };

    fetchData().then((res) => {
      let docList = res;
      let merged = [].concat.apply([], docList).filter((u) => u != undefined);

      console.log(merged);

      let docListData = merged.map((d) => {
        return {
          folderId: d.folderId,
          id: d.id,
          key: `${d.folderId}-${d.id}`,
          name: d?.info?.title && d?.info?.title !== '' ? d?.info?.title : 'Untitled',
          content: d.title && d.title !== '' ? d.title : '',
          type: 'child',
          info: d.info,
          selectedTask: d.info?.selectedTasks,
          category: d?.info?.type,
          assignee: d?.info?.createdBy?.replace(/\s{2,}/g, ' '),
          assigneeImg: d?.info?.createdBy?.replace(/\s{2,}/g, ' ')
            ? d?.info?.createdBy?.replace(/\s{2,}/g, ' ') == userName
              ? userImage
              : ''
            : '',
          upload_date: d?.info?.timestamp
            ? moment(d?.info?.timestamp).format('MM-DD-YYYY')
            : moment(d?.info?.updatedDate).format('MM-DD-YYYY'),
          linked: d?.info?.linked,
          attachments: d?.info?.comments?.length,
          details: 'true',
        };
      });

      console.log(docListData);

      let dataCombined = folders.concat(docListData);

      console.log(dataCombined);

      const result = Object.values(
        dataCombined.reduce((r, o, i) => {
          const key = `${o.folderId}`;

          if (!r[key])
            r[key] = {
              ...o,
            };

          r[key].children.unshift(o);

          r[key].children = [].concat.apply([], r[key].children).filter((u) => u !== undefined && u.type !== 'parent');

          r[key].notes = r[key]?.children?.filter((c) => c?.info?.type == 'Note').length;

          r[key].uploads = r[key]?.children?.filter((c) => c?.info?.type !== 'Note' && c.type !== 'add-button').length;

          r[key].selectedTasks = [].concat.apply(
            [],
            r[key]?.children?.map((t) => t.selectedTask).filter((u) => u !== undefined)
          );

          r[key].children.sort((x, y) => {
            return x.id - y.id;
          });

          console.log(r[key]);

          return r;
        }, {})
      );

      console.log('RESULT', result);

      let getLink = result
        .filter((e) => e.children)
        .map((e) => e.children.map((link) => link.id))
        .reduce((a, b) => a.concat(b), [])
        .filter((l) => l !== undefined);

      console.log('ALL DOC IDS', getLink);

      let params = {};

      const fetchData = () => {
        const allRequests = getLink.map((e) => getLinks(e, params).then((res) => res));

        return Promise.all(allRequests);
      };

      fetchData().then((res) => {
        if (res && res.length > 0) {
          let linkArr = [].concat.apply([], res).filter((u) => u != undefined);
          console.log('ALL LINKS', linkArr);

          const link = Array.from(
            linkArr.reduce(
              (m, { documentId, linkId }) => m.set(documentId, [...(m.get(documentId) || []), linkId]),
              new Map()
            ),
            ([documentId, linkId]) => ({ documentId, linkId })
          );

          console.log(link);

          let addSelectedLinks = result.map((o) => {
            o.children.map((c) => {
              link.map((d) => {
                if (d.documentId == c.id) {
                  c.linkeds = d.linkId.length;
                }
              });
            });
            if (
              localStorage.getItem('documentColKeyOrder') != undefined
            ) {
              let nameOrder = localStorage.getItem('documentColKeyOrder');
              if (nameOrder) {
                const getAddButton = o.children.filter((k) => k.type === 'add-button');
                const getDocs = o.children.filter((k) => k.type === 'child');
                if (nameOrder == 'ascend') {
                  getDocs.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
                } else {
                  getDocs.sort((a, b) => b.name.toLowerCase().localeCompare(a.name.toLowerCase()));
                }
                o.children = [ ...getDocs, ...getAddButton ];
              }
            } else {
              o.children.sort((x, y) => {
                return y.key - x.key;
              });
            }
            return o;
          });

          console.log(addSelectedLinks);
          if (
            localStorage.getItem('documentOrder') != undefined &&
            JSON.parse(localStorage.getItem('documentOrder')).length === addSelectedLinks.length
          ) {
            const { documentOrder, orderedDocument } = cacheTableOrder(addSelectedLinks);
            setDataSource(orderedDocument);
            localStorage.setItem('documentOrder', JSON.stringify(documentOrder));
          } else if (
            localStorage.getItem('documentColKeyOrder') != undefined
          ) {
            let nameOrder = localStorage.getItem('documentColKeyOrder');
            // const clonedData = _.cloneDeep(origSource);
            if (nameOrder) {
              // if (nameOrder == 'ascend') {
              // addSelectedLinks.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
              const tableOrdered = applyColumnOrder('name', nameOrder, addSelectedLinks);
              setDataSource(tableOrdered);
              // } else {
              //   addSelectedLinks.sort((a, b) => b.name.toLowerCase().localeCompare(a.name.toLowerCase()));
              //   setDataSource(addSelectedLinks);
              // }
            }
          } else {
            setDataSource(addSelectedLinks);
          }
          setOriginal(addSelectedLinks);
          // localStorage.setItem('originalDocuments', JSON.stringify(addSelectedLinks));
          // setHasChanged(false);
        } else {
          if (
            localStorage.getItem('documentOrder') != undefined &&
            JSON.parse(localStorage.getItem('documentOrder')).length === result.length
          ) {
            const { documentOrder, orderedDocument } = cacheTableOrder(result);
            setDataSource(orderedDocument);
            localStorage.setItem('documentOrder', JSON.stringify(documentOrder));
          } else if (
            localStorage.getItem('documentColKeyOrder') != undefined
          ) {
            let nameOrder = localStorage.getItem('documentColKeyOrder');
            // const clonedData = _.cloneDeep(origSource);
            if (nameOrder) {
              // if (nameOrder == 'ascend') {
              // result.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
              const tableOrdered = applyColumnOrder('name', nameOrder, result);
              setDataSource(tableOrdered);
              // } else {
              //   result.sort((a, b) => b.name.toLowerCase().localeCompare(a.name.toLowerCase()));
              //   setDataSource(result);
              // }
            }
          } else {
            setDataSource(result);
          }
          setOriginal(result);
          // localStorage.setItem('originalDocuments', JSON.stringify(result));
          // setHasChanged(false);
        }
      });
    });
  };

  const showDrawer = (e, obj) => {
    updateVisible(true);
    setSelectedDocument(obj);
  };

  const showNote = (e, folderId) => {
    e.preventDefault();
    updateVisible1(true);
    setFolderId(folderId);
  };

  const showDocForm = (e, folderId) => {
    e.preventDefault();
    updateVisible2(true);
    setFolderId(folderId);
  };

  const onClose = () => {
    updateVisible(false);
    updateVisible1(false);
    updateVisible2(false);
  };

  const setDocProps = (e, obj) => {
    console.log(obj);
    if (obj.category !== 'Note') {
      localStorage.setItem(
        'docProps',
        JSON.stringify({
          name: obj.name,
          date: obj.upload_date,
          file: obj.info?.file,
          type: obj.info?.type,
        })
      );
    } else {
      localStorage.removeItem('docProps');
    }
  };
  const handleAddDocumentFolder = (info) => {
    setHasChanged(true);
    if (rename) {
      updateDocFolder(info?.key, { title: info?.name.trim() }).then((res) => {
        setIsEditing(false);
        setHasChanged(false);
        setDocumentId('0');
        setRename(false);
        getAllFolders();
      });
    } else {
      createDocumentFolder({ title: info?.name.trim(), restaurantId: resto.id }).then((res) => {
        setHasChanged(false);
        setIsEditing(false);
      });
    }
  };

  const saveDocumentFolder = (e, info) => {
    e.preventDefault();
    console.log('DOC FOLDER', info);
    if (!info?.name) {
      const clonedList = cloneDeep(dataSource);
      remove(clonedList, (cl) => cl.key === '0');
      console.log(clonedList);
      setDataSource(clonedList);
    } else {
      if (rename) {
        updateDocFolder(info?.key, { title: info?.name.trim() }).then((res) => {
          setIsEditing(false);
          setHasChanged(false);
          setDocumentId('0');
          setRename(false);
          getAllFolders();
        });
      } else {
        createDocumentFolder({ title: info?.name.trim(), restaurantId: resto.id }).then((res) => {
          setHasChanged(false);
          setIsEditing(false);
        });
      }
    }
  };

  const renameFolder = (info) => {
    console.log('RENAME THIS FOLDER', info?.key);
    setDocumentId(info?.key);
    setIsEditing(true);
    setRename(true);
  };

  const closeDrawer = useCallback((data) => {
    updateVisible1(false);
    updateVisible(false);
    updateVisible2(false);
    setHasChanged(true);
  }, []);

  const deleteFolder = (info) => {
    console.log(info);
    if (info?.key * 1 > 0) {
      setHasChanged(true);
      destroyDocFolder(info?.key).then((res) => {
        console.log('Resss', res);
        setHasChanged(false);
        // notification.open({
        //   message: 'Document Folder Successfully deleted!',
        // });
      });
    } else {
      const clonedList = cloneDeep(dataSource);
      remove(clonedList, (cl) => cl.key === '0');
      console.log(clonedList);
      setDataSource(clonedList);
    }
  };

  const docFolder = (action) => {
    return (
      <Menu className="menu-show-preview">
        <Menu.Item className="menu-show-preview" onClick={() => renameFolder(action)}>
          <span>Rename</span>
        </Menu.Item>
        <Menu.Item className="menu-show-preview" onClick={() => deleteFolder(action)}>
          <span>Delete</span>
        </Menu.Item>
      </Menu>
    );
  };

  const menu = (
    <Menu className="menu-show-preview">
      {/* <Menu.Item className="menu-show-preview">
        <a target="_blank" rel="noopener noreferrer" href="">
          Upload
        </a>
      </Menu.Item> */}
      {/* <Menu.Item className="menu-show-preview">
        <a target="_blank" rel="noopener noreferrer" href="">
          Create note
        </a>
      </Menu.Item> */}
      <Menu.Item className="menu-show-preview">
        <span
          onClick={() => {
            const clonedDataSource = cloneDeep(dataSource);
            const hasNew = find(clonedDataSource, { key: '0' });
            if (!hasNew) {
              clonedDataSource.unshift(newDocsValue);
              setDataSource(clonedDataSource);
              setIsEditing(true);
              window.scrollTo(0, 0);
            }
          }}
        >
          Add document folder
        </span>
      </Menu.Item>
    </Menu>
  );

  const filterDocs = (e, type, typeName) => {
    setIsSearching(false);
    console.log(origSource);
    setTypeName(typeName);
    let toDisplay = [];
    if (type == 'All') {
      setIsFiltering(false);
      toDisplay = cloneDeep(origSource);
    } else {
      setIsFiltering(true);
      let clonedOrig = cloneDeep(origSource);
      toDisplay = clonedOrig
        .map((obj) => {
          obj.children = obj.children.filter(
            (doc) => doc.type !=='add-button' && 
            doc.category.indexOf(type) >= 0
          );
          return obj;
        })
        .filter((c) => c.children.length);
    }
    const viewOnly = type !== 'All' ? true : false;
    
    if (
      localStorage.getItem('documentOrder') != undefined &&
      JSON.parse(localStorage.getItem('documentOrder')).length
    ) {
      const documentOrder = JSON.parse(localStorage.getItem('documentOrder'));
      const tableOrdered = annotateTableOrder(toDisplay, documentOrder, viewOnly);
      setDataSource(tableOrdered);
    } else if (
      localStorage.getItem('documentColKeyOrder') != undefined
    ) {
      let nameOrder = localStorage.getItem('documentColKeyOrder');
      // if (nameOrder === 'ascend') {
      // toDisplay.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
      const tableOrdered = applyColumnOrder('name', nameOrder, toDisplay, viewOnly);
      setDataSource(tableOrdered);
      // } else {
      //   toDisplay.sort((a, b) => b.name.toLowerCase().localeCompare(a.name.toLowerCase()));
      //   setDataSource(toDisplay);
      // }
    } else {
      setDataSource(toDisplay);
    }
  };

  const menu1 = (
    <Menu className="menu-show-preview">
      <Menu.Item className="menu-show-preview" onClick={(e) => filterDocs(e, 'All')}>
        <span>All</span>
      </Menu.Item>
      <Menu.Item className="menu-show-preview" onClick={(e) => filterDocs(e, 'Word', 'Word only')}>
        <span>Word only</span>
      </Menu.Item>
      <Menu.Item className="menu-show-preview" onClick={(e) => filterDocs(e, 'Excel', 'Excel only')}>
        <span>Excel only</span>
      </Menu.Item>
      <Menu.Item className="menu-show-preview" onClick={(e) => filterDocs(e, 'PDF', 'PDF only')}>
        <span>PDF only</span>
      </Menu.Item>
      <Menu.Item className="menu-show-preview" onClick={(e) => filterDocs(e, 'image', 'Photo only')}>
        <span>Photo (JPEG, PNG, GIF etc.) only</span>
      </Menu.Item>
      <Menu.Item className="menu-show-preview" onClick={(e) => filterDocs(e, 'Video', 'Video only')}>
        <span>Video (MOV, QT, etc) only</span>
      </Menu.Item>
      <Menu.Item className="menu-show-preview" onClick={(e) => filterDocs(e, 'Note', 'Note only')}>
        <span>Note only</span>
      </Menu.Item>
      <Menu.Item className="menu-show-preview" onClick={(e) => filterDocs(e, 'Powerpoint', 'Powerpoint only')}>
        <span>Powerpoint only</span>
      </Menu.Item>
    </Menu>
  );

  // const detachDocument = (info) => {
  //   setDocumentId(info.id.toString());
  //   setFolderId(info.folderId.toString());
  //   deleteAllLinks(info.id)
  //     .then((res) => {
  //       setTimeout(() => {
  //         setHasChanged(true);
  //       }, 2000);
  //     })
  //     .catch((err) => console.log(err));
  // };

  const customSorter = (a, b, sortOrder) => {
    if (a.type !== b.type) {
      if (sortOrder === 'descend') {
        return -1;
      } else {
        return 1;
      }
    } else {
      if (a.type === 'parent') {
        return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
      }
      if (a.type === 'child') {
        return a.content.toLowerCase().localeCompare(b.content.toLowerCase());
      }
    }
  }

  const columns = [
    {
      title: 'Sorted by Name',
      dataIndex: 'name',
      sorter: (a, b, sortOrder) => (sortOrder != undefined ? customSorter(a, b, sortOrder) : null),
      sortDirections: ['descend', 'ascend'],
      width: 320,
      ellipsis: true,
      render: (key, obj) => (
        <span className="chk-list-mobile">
          <span>
            <span className={obj.type == 'parent' ? '' : 'doc-name-key'}>
              {obj.type == 'child' ? (
                <ThumbnailImage
                  className="doc-image-container-mobile"
                  src={docThumbnailSrc(obj)}
                  placeholderIcon={docIcon(obj)}
                  onClick={(e) => showDrawer(e, obj)}
                />
              ) : (
                ''
              )}
              {/* {key && (
                <span onClick={obj.type == 'parent' ? '' : showDrawer} style={{ marginLeft: 15 }}>
                  {key}
                </span>
              )} */}

              {/* {obj.type == 'child' ? (
                <span style={{ marginLeft: 15 }}>{key && <Link to={`/document/public/${obj.key}`}> {key}</Link>}</span>
              ) : (
                <span style={{ marginLeft: 15 }}>{key}</span>
              )} */}
              {obj.type == 'child' || obj.type == 'add-button' ? (
                <span className="doc-title-container" onClick={(e) => setDocProps(e, obj)}>
                  {key && (
                    <span onClick={(e) => showDrawer(e, obj)}>
                      {obj.category !== 'Note' ? obj.content : key}
                      {/* <Link
                        target={obj.category !== 'Note' ? '_blank' : ''}
                        to={
                          obj.category !== 'Note'
                            ? {
                                pathname: `/document/public/${obj.id}`,
                              }
                            : '/documents'
                        }
                      >
                        {' '}
                        {key}
                      </Link> */}
                    </span>
                  )}
                </span>
              ) : obj.type === 'parent' && (documentid == obj.key || obj.key === '0') && isEditing ? (
                <span>
                  <input
                    autoFocus
                    type="text"
                    className="document-bar1"
                    defaultValue={obj.name}
                    onKeyPress={(e) => {
                      if (e.key === 'Enter') {
                        handleAddDocumentFolder(obj);
                      }
                    }}
                    onBlur={(e) => saveDocumentFolder(e, obj)}
                    onChange={(e) => {
                      const cloneList = cloneDeep(dataSource);
                      const index = findIndex(cloneList, { key: obj.key });
                      cloneList[index].name = e.target.value;
                      setDataSource(cloneList);
                    }}
                    placeholder="Enter document folder name"
                  />
                  <div
                    onCancel={() => cancelAddMenu()}
                    style={{
                      width: 100,
                      cursor: 'pointer',
                      margin: '8px 0px 7px 45px',
                      color: 'var(--accent-color)',
                      fontSize: 15,
                    }}
                  >
                    Cancel
                  </div>
                </span>
              ) : (
                <span className="attached-doc-folder-title">{key}</span>
              )}
              {obj.type === 'parent' && obj.key !== '0' ? (
                <div className="attached-doc-upload-count">
                  ( {obj?.uploads} uploads, {obj?.notes} notes )
                  <span className="checklist_more">
                    <span>
                      <Dropdown overlay={docFolder(obj)}>
                        <div className="toggle-switch">
                          <img src={IconsMoreOff} className="UI-IconsMoreOff" alt="IconsMoreOff" />
                        </div>
                      </Dropdown>
                    </span>{' '}
                  </span>
                </div>
              ) : (
                ''
              )}
              {obj.type == 'child' && obj.attachments > 0 ? (
                <span className={'doc-comments-count'}>{obj.attachments}</span>
              ) : (
                ''
              )}
              {obj.type == 'child' && obj.attachments > 0 ? (
                <span className={'doc-comments-icon-container'}>
                  {' '}
                  <img src={comment} alt="comment" />
                </span>
              ) : (
                ''
              )}
              {/* {!key && <span className="doc-item-add">Upload</span>}
              {!key && (
                <span className="doc-item-add" onClick={showNote}>
                  Create note
                </span>
              )} */}
              {obj.type == 'add-button' ? (
                <span>
                  {!key && (
                    <span className="doc-item-add" onClick={(e) => showDocForm(e, obj.parent_id)}>
                      Upload
                    </span>
                  )}
                  {!key && (
                    <span className="doc-item-add" onClick={(e) => showNote(e, obj.parent_id)}>
                      Create note
                    </span>
                  )}
                </span>
              ) : (
                ''
              )}
            </span>
          </span>
          <div className="chk-mobile-details">
            {obj.type == 'child' && (
              <span className="chk-mobile-title">
                Type
                <span className="doc-mobile-project">
                  {obj.type == 'child' && obj.category ? <span>{obj.category}</span> : ''}
                </span>
              </span>
            )}
          </div>
          <div className="chk-mobile-details">
            {obj.type == 'child' && (
              <span className="chk-mobile-title">
                Date uploaded
                <span className="chk-mobile-due">
                  {obj.type == 'child' && obj.upload_date ? <span>{obj.upload_date}</span> : ''}
                </span>
              </span>
            )}
          </div>
          <div className="chk-mobile-details">
            {obj.type == 'child' && (
              <span className="chk-mobile-title">
                Linked
                <span className="doc-mobile-project1">
                  {obj.type == 'child' && obj.linkeds ? <span>{obj.linkeds}</span> : ''}
                </span>
              </span>
            )}
            {/* {obj.type == 'child' && (
														<span className='detach-docs-mobile' onClick={() => detachDocument(obj)}>Detach</span>
												)} */}
          </div>
          <div className="chk-mobile-details">
            {obj.type == 'child' && (
              <span className="chk-mobile-title">
                Uploaded by
                <span className="chk-mobile-assignee1">
                  {obj.type == 'child' && obj.assignee ? (
                    <div className="common-user-icon" alt="user-photo">
                      <UserImage src={obj.assigneeImg !== '' ? obj.assigneeImg : null} />
                    </div>
                  ) : (
                    ''
                  )}
                  {obj.type == 'child' && obj.assignee ? <span>{obj.assignee}</span> : ''}
                </span>
              </span>
            )}
          </div>
          {/* <div className="chk-mobile-details">
            {obj.type == 'child' && (
              <span className="chk-mobile-title">
                Details
                <span className="chk-mobile-project2">
                  {obj.type == 'child' && obj.details ? <span onClick={(e) => showDrawer(e, obj)}>Details</span> : ''}
                </span>
              </span>
            )}
          </div> */}
        </span>
      ),
    },
  ];

  const handleRowExpand = (record) => {
    if (expandedRows.includes(record.key)) {
      let filterRecord = expandedRows.filter((key) => key !== record.key);
      setExpandedRows(filterRecord);
    } else {
      setExpandedRows([...expandedRows, record.key]);
    }
  };

  const searchDocs = (e) => {
    setIsFiltering(false);
    let inputValue = e.target.value;

    const clonedData = _.cloneDeep(origSource);

    if (inputValue.length) {
      setIsSearching(true);
      let searchString = inputValue.toLowerCase();

      let results = clonedData.filter(searchAllArr(inputValue));

      const searchTags = results.map((item) => ({
        ...item,
        children: item.children?.filter(
          (child) =>
            (typeof child.name == 'string' && child.name?.toLowerCase().indexOf(searchString) !== -1) ||
            (typeof child.assignee == 'string' && child.assignee?.toLowerCase().indexOf(searchString) !== -1) ||
            (typeof child.category == 'string' && child.category?.toLowerCase().indexOf(searchString) !== -1) ||
            pluralize(typeof child.name == 'string' && child.name?.toLowerCase()).indexOf(searchString) !== 1 ||
            pluralize(typeof child.assignee == 'string' && child.assignee?.toLowerCase()).indexOf(searchString) !== -1 ||
            pluralize(typeof child.category == 'string' && child.category?.toLowerCase()).indexOf(searchString) !== -1
        ),
      }));

      if (
        localStorage.getItem('documentOrder') != undefined &&
        JSON.parse(localStorage.getItem('documentOrder')).length
      ) {
        const documentOrder = JSON.parse(localStorage.getItem('documentOrder'));
        const tableOrdered = annotateTableOrder(searchTags, documentOrder, true);
        setDataSource(tableOrdered);
      } else if (
        localStorage.getItem('documentColKeyOrder') != undefined
      ) {
        const nameOrder = localStorage.getItem('documentColKeyOrder');
        // if (nameOrder === 'ascend') {
        // searchTags.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
        const tableOrdered = applyColumnOrder('name', nameOrder, searchTags, true);
        setDataSource(tableOrdered);
        // } else {
        //   searchTags.sort((a, b) => b.name.toLowerCase().localeCompare(a.name.toLowerCase()));
        //   setDataSource(searchTags);
        // }
      } else {
        setDataSource(searchTags);
      }
    } else {
      setIsSearching(false);
      if (
        localStorage.getItem('documentOrder') != undefined &&
        JSON.parse(localStorage.getItem('documentOrder')).length
      ) {
        const documentOrder = JSON.parse(localStorage.getItem('documentOrder'));
        const tableOrdered = annotateTableOrder(clonedData, documentOrder, true);
        setDataSource(tableOrdered);
      } else if (
        localStorage.getItem('documentColKeyOrder') != undefined
      ) {
        const nameOrder = localStorage.getItem('documentColKeyOrder');
        // if (nameOrder === 'ascend') {
        // clonedData.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
        const tableOrdered = applyColumnOrder('name', nameOrder, clonedData);
        setDataSource(tableOrdered);
        // } else {
        //   clonedData.sort((a, b) => b.name.toLowerCase().localeCompare(a.name.toLowerCase()));
        //   setDataSource(clonedData);
        // }
      } else {
        setDataSource(clonedData);
      }
    }
  };

  const searchAllArr = (text) => (value) => {
    if (!value) return false;
    const valueType = typeof value;

    if (valueType === 'string') {
      return value.toLowerCase().indexOf(text.toLowerCase()) > -1;
    }
    if (Array.isArray(value)) {
      return value.some(searchAllArr(text));
    }
    if (valueType === 'object') {
      return Object.values(value).some(searchAllArr(text));
    }
    return false;
  };

  const cancelAddMenu = () => {
    const clonedList = cloneDeep(dataSource);
    remove(clonedList, (cl) => cl.key === '0');
    console.log(clonedList);
    setDataSource(clonedList);
  };

  const updateComments = useCallback((data) => {
    setHasChanged(true);
  }, []);

  // applyColumnOrder - apply cached ANTD column order to dataSource
  const applyColumnOrder = (column, status, list = [], viewOnly = false) => {
    let rows = list.length ? list : cloneDeep(origSource);
    let tableRows = rows.filter((r) => r.key !== '0');
    let toAddFolder = rows.filter((r) => r.key === '0');
    let orderedRows = [];

    if (tableRows.length) {
      // if single result, return
      if (rows.length === 1 && rows[0].children.length === 1) {
        return list;
      }

      orderedRows = tableRows.map((p) => {
        if (p.children !== undefined && p.children.length > 2) {
          let getRows = p.children.filter((c) => c.type !== 'add-button');
          let buttonRow = p.children.filter((c) => c.type === 'add-button');

          if (column === 'name') { // string value column keys
            if (status === 'ascend') {
              getRows = getRows.sort((a, b) => a.content.toLowerCase().localeCompare(b.content.toLowerCase()));
            } else {
              getRows = getRows.sort((a, b) => b.content.toLowerCase().localeCompare(a.content.toLowerCase()));
            }
          }
          if (!viewOnly) {
            getRows.push(buttonRow[0]);
          }
          p.children = getRows;
        }
        return p;
      });

      // if more than 1 parent rows and column key sorter is 'name', sort as well
      if (orderedRows.length > 1 && column === 'name') {
        if (status === 'ascend') {
          orderedRows = orderedRows.sort((a, b) => a[column].toLowerCase().localeCompare(b[column].toLowerCase()));
        } else {
          orderedRows = orderedRows.sort((a, b) => b[column].toLowerCase().localeCompare(a[column].toLowerCase()));
        }
      }

      if (!viewOnly && toAddFolder.length) {
        // column sorting does not apply but open add rows always at the top
        orderedRows.unshift(toAddFolder[0]);
      } 
    }
    console.log(orderedRows);
    return orderedRows;
  };

  // cacheTableOrder - caches and annotates position of each parent and child row of current table order (index-based position)
  const cacheTableOrder = (data) => {
    let orderCached = [];
    let tableOrdered = [];
    if (data.length > 0) {
      tableOrdered = data.map((d, i) => { // for menu categories
        const parentRow = {};
        parentRow.key = d.key;
        if (d?.order == undefined || d.order !== i) {
          parentRow.order = i; // caches order
          d.order = i; // annotates order
        } else {
          parentRow.order = d.order;
        }
        parentRow.children = [];
        if (d.children && d.children.length) {
          d.children = d.children.map((c, j) => { // for menus
            const childRow = {};
            childRow.key = c.key;
            if (c?.order == undefined || c.order !== j) {
              childRow.order = j; // caches order
              c.order = j; // annotates order
            } else {
              childRow.order = c.order;
            }
            parentRow.children.push(childRow);
            return c;
          });
          if (d.children.length > 1) {
            d.children = d.children.sort((a, b) => a.order - b.order);
          }
        }
        orderCached.push(parentRow);
        return d;
      });
      if (data.length > 1) {
        tableOrdered = tableOrdered.sort((a, b) => a.order - b.order);
      }
    }
    return {
      documentOrder: orderCached, // cached table order
      orderedDocument: tableOrdered // annotated table rows
    };
  }

  // annotateTableOrder - annotates new/current/updated order for each parent and child record of table (index-based position)
  // callback when localStorage variable 'documentOrder' exists, then applies to fetched data
  const annotateTableOrder = (data, documentOrder, viewOnly = false) => {
    let orderedTable = [];
    if (data.length > 0) {
      const tableAnnotated = data.map((d) => {
        // annotate document folder cached order
        const folderIndex = findIndex(documentOrder, (r) => r.key === d.key);
        d.order = documentOrder[folderIndex].order;
        if (d.children && d.children.length) {
          d.children = d.children.map((c, i) => {
            // annotate document cached order
            const documentIndex = findIndex(documentOrder[folderIndex].children, (h) => h.key === c.key);
            if (documentOrder[folderIndex]?.children[documentIndex] != undefined) {
              const cachedOrder = documentOrder[folderIndex]?.children[documentIndex].order;
              c.order = (
                cachedOrder != undefined &&
                (
                  !viewOnly && cachedOrder !== i
                )
              ) ? cachedOrder : i;
            } else {
              c.order = i;
            }
            return c;
          });

          if (viewOnly) { // removes open add documents
            d.children = d.children.filter((a) => a.type !== 'add-button');
          }
          if (d.children.length > 1) {
            d.children = d.children.sort((a, b) => a.order - b.order);
          }
        }
        return d;
      });

      orderedTable = tableAnnotated;

      if (viewOnly) { // removes open add folder
        orderedTable = tableAnnotated.filter((a) => a.key !== '0');
      }

      if (data.length > 1) {
        orderedTable = tableAnnotated.sort((a, b) => a.order - b.order);
      }
    }
    return orderedTable;
  }

  // deannotateTableOrder - removes index order for each parent and child record
  const deannotateTableOrder = (data) => {
    if (data.length > 0) {
      // filters out open add document folder rows
      let orderRemoved = data;
      
      if (orderRemoved.length) {
        orderRemoved = orderRemoved.map((d) => {
          if (d?.order != undefined) {
            delete d['order'];
          }
          if (d.children && d.children.length) {
            d.children = d.children.map((e) => { 
              if (e?.order != undefined) {
                delete e['order'];
              }
              return e;
            });
            if (d.children.length > 1) {
              d.children = d.children.sort((a, b) => a.key.localeCompare(b.key));
            }
          }
          return d;
        });
        if (orderRemoved.length > 1) {
          orderRemoved = orderRemoved.sort((a, b) => a.key.localeCompare(b.key));
        }
        return orderRemoved;
      }
    }
    return data;
  }

  // removeOpenAdds - remove open add rows in documentRows
  const removeOpenAdds = (documentRows) => {
    let removedOpenAdds = documentRows.filter((k) => k.key === '0'); // removes open add document folder rows
    if (removedOpenAdds.length > 1) {
      // table rows with removed open add rows might have index-based order values
      // making sorting formula inaccurate with simple subtraction
      // used boolean comparison instead on ternary
      // then sorted with new assigned index as order values for each
      return removedOpenAdds.sort((a, b) => a.order > b.order ? 1 : -1)
        .map((l, i) => {
          if (l.order * 1 !== i) {
            l.order = i;
          }
          return l;
        }).sort((a, b) => a.order - b.order);
    } else {
      return removedOpenAdds;
    }
  }

  const onTableChange = (pagination, filters, sorter, extra) => {
    const data = [...dataSource];
    // const current = extra['currentDataSource'];
    if (data.length) {
      const viewOnly = isFiltering || isSearching ? true : false;
      // if sorter is neither ascending nor descending, 
      // resort to manual document table order
      // or sort by key
      // else save last auto table order (ascending or descending)

      if (sorter?.order == undefined) {
        if (viewOnly) {
          setDataSource(data);
        } else {
          const restoredOrder = deannotateTableOrder(data);
          setDataSource(restoredOrder);
        }
        localStorage.removeItem('documentColKeyOrder');
      } else {
        localStorage.setItem('documentColKeyOrder', sorter.order);
        // extra['currentDataSource'] = [...restoredOrder]; // ANTD Table remains uncontrolled component when applying newly sorted order from custom function, to be changed...
      }
    }
  };

  return (
    <div className="marginT10 windowHFull">
      <SubHeaderMobile
        title="Documents"
        restaurant={restaurant}
        showSearch={showSearch}
        onChange={searchDocs}
        clickSearch={() => setShowSearch(true)}
      />
      <div className="checklist-line"></div>
      <div className="check-controls">
        {/* <Dropdown overlay={menu1}>
          <div className="incomplete-task-mobile ant-dropdown-link" onClick={(e) => e.preventDefault()}>
            <div>
              All
              <span className="doc-icon">
                <i className="fa fa-chevron-down" aria-hidden="true"></i>
              </span>
            </div>
          </div>
        </Dropdown> */}
        <Dropdown overlay={menu1}>
          <div className="incomplete-task-mobile ant-dropdown-link" onClick={(e) => e.preventDefault()}>
            <div>
              <span>{typeName ? typeName : 'All documents'}</span>
              <span className="doc-icon-mob">
                <i className="fa fa-chevron-down" aria-hidden="true"></i>
              </span>
            </div>
          </div>
        </Dropdown>
        <Dropdown overlay={menu}>
          <div className="add-task-mobile ant-dropdown-link" onClick={(e) => e.preventDefault()}>
            <PlusButton
              alt="Add Folder"
              onClick={() => {
                const clonedDataSource = cloneDeep(dataSource);
                const hasNew = find(clonedDataSource, { key: '0' });
                if (!hasNew) {
                  clonedDataSource.push(newDocsValue);
                  setDataSource(clonedDataSource);
                  setIsEditing(true);
                }
              }}
            >
              Add Folder
            </PlusButton>
          </div>
        </Dropdown>
        {/* <Dropdown overlay={menu}>
          <div className="dropdown-icon-mobile ant-dropdown-link" onClick={(e) => e.preventDefault()}>
            <i className="fa fa-chevron-down" aria-hidden="true"></i>
          </div>
        </Dropdown> */}
      </div>
      <div className="collapse-check-mobile">
        <div onClick={() => setExpandedRows([])}>
          <img src={collapseall} className="collapse-all-check" alt="add-button" />
          Collapse all
        </div>
      </div>
      <div className="checklist-table">
        <Table
          columns={columns}
          dataSource={dataSource}
          rowClassName={(record, index) => (record.type == 'parent' ? 'parent' : 'child')}
          pagination={false}
          className={'show-custom-empty'}
          locale={{ emptyText: <Empty image={empty} description="No documents" /> }}
          expandIcon={(props) => {
            if (props.record.type == 'parent') {
              return (
                <ExpandCollapseIcon
                  expanded={props.expanded}
                  onClick={(e) => {
                    props.onExpand(props.record, e);
                  }}
                />
              );
            }
          }}
          onExpand={(expanded, record) => handleRowExpand(record)}
          expandedRowKeys={expandedRows}
          rowKey={'key'}
          onChange={onTableChange}
        />
      </div>
      <div
        className="add-checklist-mobile"
        onClick={() => {
          const clonedDataSource = cloneDeep(dataSource);
          const hasNew = find(clonedDataSource, { key: '0' });
          if (!hasNew) {
            clonedDataSource.unshift(newDocsValue);
            setDataSource(clonedDataSource);
            setIsEditing(true);
            window.scrollTo(0, 0);
          }
        }}
      >
        Add document folder
      </div>
      <Drawer
        width={windowSize.width > 800 ? '600px' : '100%'}
        closable={false}
        onClose={onClose}
        visible={visible}
        drawerStyle={{ backgroundColor: 'var(--main-fill)' }}
        destroyOnClose={true}
      >
        <div style={{ marginTop: 15 }}>
          <Row gutter={32}>
            <Col className="gutter-row" span={21}></Col>
            <Col className="gutter-row" span={3}>
              <Button
                className="close-add-event"
                onClick={() => {
                  updateVisible(false);
                  updateVisible1(false);
                  updateVisible2(false);
                }}
                type="primary"
                shape="circle"
                size={`small`}
              >
                <CloseCircleOutlined />
              </Button>
            </Col>
          </Row>
        </div>
        <DocumentsDetailPopup data={selectedDocument} closeDrawer={closeDrawer} updateComments={updateComments} />
      </Drawer>

      <Drawer
        width={windowSize.width > 800 ? '600px' : '100%'}
        onClose={onClose}
        visible={visible1}
        drawerStyle={{ backgroundColor: 'var(--main-fill)' }}
      >
        <div style={{ marginTop: 15 }}>
          <Row gutter={32}>
            <Col className="gutter-row" span={21}></Col>
            <Col className="gutter-row" span={3}>
              <Button
                className="close-add-event"
                onClick={() => {
                  updateVisible(false);
                  updateVisible1(false);
                  updateVisible2(false);
                  // getAllFolders();
                  setHasChanged(true);
                }}
                type="primary"
                shape="circle"
                size={`small`}
              >
                <CloseCircleOutlined />
              </Button>
            </Col>
          </Row>
        </div>
        <CreateNotePopup 
          closeDrawer={closeDrawer} 
          folder={folderid} 
          visible={visible1} 
        />
      </Drawer>
      <Drawer
        width={windowSize.width > 800 ? '600px' : '100%'}
        onClose={onClose}
        visible={visible2}
        drawerStyle={{ backgroundColor: 'var(--main-fill)' }}
      >
        <div style={{ marginTop: 15 }}>
          <Row gutter={32}>
            <Col className="gutter-row" span={21}></Col>
            <Col className="gutter-row" span={3}>
              <Button
                className="close-add-event"
                onClick={() => {
                  updateVisible(false);
                  updateVisible1(false);
                  updateVisible2(false);
                }}
                type="primary"
                shape="circle"
                size={`small`}
              >
                <CloseCircleOutlined />
              </Button>
            </Col>
          </Row>
        </div>
        <UploadDocument 
          closeDrawer={closeDrawer}
          folder={folderid}
          visible={visible2}
        />
      </Drawer>
    </div>
  );
};

export default DocumentsMobile;
